<template>
    <v-row v-resize="onResize" no-gutters class="fill-height">
        <v-col
            cols="12"
            md="3"
            sm="3"
            lg="3"
            ref="menu"
            class="border-right d-flex flex-column flex-nowrap"
            :style="`height: ${height}px;`"
        >
            <v-row no-gutters class="border-bottom flex-grow-0">
                <!-- Search -->
                <v-col cols="12" class="pa-2 pb-0">
                    <v-text-field
                        v-model="search"
                        clearable
                        dense
                        filled
                        hide-details="auto"
                        placeholder="Search"
                        prepend-inner-icon="mdi-magnify"
                        rounded
                    />
                </v-col>
                <!-- Sort -->
                <v-col
                    cols="12"
                    class="py-2 px-2 d-flex align-center justify-start"
                >
                    <v-select
                        v-model="sortBy"
                        flat
                        :items="sortOptions"
                        hide-details="auto"
                        solo
                        :append-icon="null"
                        :append-outer-icon="
                            desc ? 'mdi-chevron-down' : 'mdi-chevron-up'
                        "
                        @click:append-outer="desc = !desc"
                    />
                </v-col>
            </v-row>
            <div
                v-if="loading"
                class="d-flex align-center justify-center flex-grow-1"
            >
                <v-progress-circular color="primary" indeterminate />
            </div>
            <!-- Users list -->
            <v-list v-else class="overflow-y-auto pa-0">
                <template v-if="filteredTools.length > 0">
                    <v-list-item-group>
                        <v-list-item
                            v-for="(tool, i) in filteredTools"
                            :key="i"
                            color="primary"
                            three-line
                            @click="hide"
                            link
                            :to="{ name: tool.name, params: { tool } }"
                            :class="{
                                'border-bottom': i < filteredTools.length - 1,
                            }"
                        >
                            <v-list-item-content>
                                <v-list-item-title
                                    class="mt-3 text-capitalize"
                                    >{{ tool.id }}</v-list-item-title
                                >
                                <v-list-item class="pa-0">
                                    <v-list-item-icon class="mt-2 mb-0">
                                        <v-icon> mdi-tools </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-subtitle class="mt-0"
                                        >Tools</v-list-item-subtitle
                                    >
                                </v-list-item>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </template>
            </v-list>
        </v-col>
        <v-col
            ref="content"
            cols="12"
            sm="9"
            md="9"
            lg="9"
            :style="`height: ${height}px`"
        >
            <router-view @show="show" />
        </v-col>
    </v-row>
</template>

<script>
import API from '@/services/api'
import { mapState, mapActions, mapMutations } from 'vuex'
import { storage } from '@/services/firebase'
export default {
    name: 'Tools',
    data() {
        return {
            loading: false,
            height: 0,
            search: null,
            sortBy: 'name',
            loadingError: false,
            folderImages: 'photo_galery',
            folderDocCommercial: 'commercial_documents',
            folderDocTechnical: 'technical_documents',
            company: JSON.parse(localStorage.getItem('company')),
            errorMsg: null,
            sortOptions: [
                {
                    text: 'Name',
                    value: 'name',
                },
            ],
            desc: true,
        }
    },
    computed: {
        filteredTools() {
            function compare(key, desc) {
                return function innerSort(a, b) {
                    if (
                        !Object.prototype.hasOwnProperty.call(a, key) ||
                        !Object.prototype.hasOwnProperty.call(b, key)
                    ) {
                        return 0
                    }

                    const varA =
                        typeof a[key] === 'string'
                            ? a[key].toUpperCase()
                            : a[key]
                    const varB =
                        typeof b[key] === 'string'
                            ? b[key].toUpperCase()
                            : b[key]

                    let comparison = 0
                    if (varA > varB) {
                        comparison = 1
                    } else if (varA < varB) {
                        comparison = -1
                    }

                    return desc ? comparison * -1 : comparison
                }
            }
            return this.tools

                .filter(q => {
                    if (this.search)
                        return q.name
                            .toLowerCase()
                            .includes(this.search.toLowerCase())
                    return true
                })
                .sort(compare(this.sortBy, this.desc))
        },
        ...mapState(['tools']),
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        onResize() {
            this.height =
                window.innerHeight - this.$vuetify.application.top - 40
        },
        ...mapActions(['addToolsAction']),
        hide() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$refs.menu.classList.remove('d-flex')
                this.$refs.menu.classList.add('d-none')
            }
        },
        show() {
            this.$refs.menu.classList.remove('d-none')
            this.$refs.menu.classList.add('d-flex')
        },
    },
    watch: {
        tools: function(newTool) {
            const params = this.$router.currentRoute.params.tool
            const route = this.$router.currentRoute.name
            if (!params) {
                if (route == 'commercialImages') {
                    const tool = newTool.find(i => i.name == 'imagesTools')
                    this.$router.currentRoute.params.tool = tool
                } else {
                    const tool = newTool.find(i => i.name == 'documentsTools')
                    this.$router.currentRoute.params.tool = tool
                }
            }
        },
    },
    async created() {
        if (this.tools.length == 0) {
            try {
                this.loadingError = false
                this.errorMsg = null
                this.loading = true
                const {
                    data: { tools },
                } = await API.getTools()
                const indexDoc = tools.findIndex(
                    x => x.name == 'documentsTools'
                )
                if (tools.length > 0) {
                    if (indexDoc >= 0) {
                        for (let doc of tools[indexDoc].commercialDocuments ||
                            []) {
                            if (doc.file) {
                                const path = `${this.company}/${this.folderDocCommercial}`
                                const storageRef = storage().ref(path)
                                const profilePicRef = storageRef.child(doc.file)
                                await profilePicRef
                                    .getDownloadURL()
                                    .then(URL => {
                                        doc.docURL = URL
                                    })
                                    .catch(error => {
                                        console.log(error)
                                    })
                                if (!doc.filename) {
                                    const meta = await profilePicRef.getMetadata()
                                    doc.filename = meta.customMetadata.filename.replaceAll(
                                        '+',
                                        ' '
                                    )
                                }
                            }
                        }
                        for (let doc of tools[indexDoc].technicalDocuments ||
                            []) {
                            if (doc.file) {
                                const path = `${this.company}/${this.folderDocTechnical}`
                                const storageRef = storage().ref(path)
                                const profilePicRef = storageRef.child(doc.file)
                                await profilePicRef
                                    .getDownloadURL()
                                    .then(URL => {
                                        doc.docURL = URL
                                    })
                                    .catch(error => {
                                        console.log(error)
                                    })
                                if (!doc.filename) {
                                    const meta = await profilePicRef.getMetadata()
                                    doc.filename = meta.customMetadata.filename.replaceAll(
                                        '+',
                                        ' '
                                    )
                                }
                            }
                        }
                    }
                }
                this.addToolsAction(tools)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        }
    },
}
</script>
<style scoped>
.border-right {
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: #f0f0f0;
}
.border-bottom {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #f0f0f0;
}
</style>
